
function Loader() {
        return (
            <>
                <div className="loader">
                    <div className="spinner"><div className="double-bounce1"></div><div className="double-bounce2"></div></div>
                </div>
    
                <div className="click-capture"></div>
            </>
        )
    }
    
    export default Loader