import React from 'react'

function Partners({ partners,skills }) {
    return (
        <>
            <section id="skills" className="section pp-scrollable position-absolute">
                <div className="intro">
                    <div className="scroll-wrap">
                        <div className="container">
                        <div className="row skill_row align-items-center">
                             <div className=" col-sm-10 col-12 col-lg-5 mb-5">
                                                                <div data-global-hover="true"
                                                                        className="por_skill flex column border-radius-4 overflow-hidden bg-gray-darkest p-12 styles_feature__JumHp styles_stock__MBToi styles_x2__KFGap">
                                                                        <div className="poa w-full top-0 left-0 right-0">
                             <h2 className="mb-0"><span className="text-white">Travel Partner</span> </h2>

                                                                            <a href=""><img className="part_img" src="img/ezzy-travels.webp" alt="" /></a>
                                                                               
                                                                        </div>
                                                                        <div className="mt-auto">
                                                                              
                                                                                <p className="skill_subtitle">
                                                                                Ezzy Travels offer one stop solution for Air Ticketing, Visa Processing and Hotel booking around the world.
                                                                                </p>
                                                                                <a target="_blank" rel="noreferrer" href="https://www.facebook.com/EzzyTravelsOfficial" className="btn">Buy Tickets</a>
                                                                               
                                                                        </div>
                                                                </div>
                                                             
                                                        </div>
                                                        <div className="col-lg-5">
                                    <div className="position-relative">
                                        <img alt="" className="border-radius w-100" src="img/team.webp" loading="lazy" />
                                        <div className="colab_div">
                                         <a target="_blank" rel="noreferrer" href="https://forms.gle/N3nKWxkhaNN2XCpm9" className="btn ">Collaboration</a>
                                        </div>

                                    </div>
                                </div>
                                                      
                                                        </div>
                                                        
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Partners