import React from 'react';
import Typewriter from 'typewriter-effect';


function Masthead({ description, name }) {
    return (
        <>
            <section id="home"
                className="navbar-is-white text-white pp-scrollable d-flex align-items-center section position-absolute"
                style={{ backgroundImage: "url('img/bg/masthead.webp')" }} role="main">
                <div className="intro">
                    <div className="scroll-wrap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-xl-5">
                                    <div className="mb-2" dangerouslySetInnerHTML={{__html: description}}></div>
                                    <h1 className="text-white hero_heading"><span
                                        className="text-primary text-typed a-typed a-typed-about">Hi,</span> I'm <br/> 
                                        
                                          {name}</h1>
                                          
                                    <div className="line"><h4 className="web_name text-gray"><span className="sub_text">Welcome to Arif's</span> <Typewriter
  options={{
    strings: ['DOT PRO'],
    autoStart: true,
    loop: true,
  }}
/></h4></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Masthead
