import About from './components/About';
import Blogs from './components/Blogs';
import Contact from './components/Contact';
import Loader from './components/Loader';
import Masthead from './components/Masthead';
import Nav from './components/Nav';
import Partners from './components/Partners';
import Projects from './components/Projects';
import Scrollbar from './components/Scrollbar';
import Skills from './components/Skills';
import Work from './components/Work';

import { about, main, partners, projects, skills } from './data/resumeData';

function App() {
  return (
    <>
    <Loader/>
      <Nav {...main} />
      <div id="pagepiling">
        <Masthead {...main} />
        <About {...about} />
        <Skills {...skills}/>
        <Projects projects={projects} github="https://github.com/arifalmas" />
        <Blogs partners={Blogs} />
        <Work Work={Work} />
        <Partners partners={partners} />
        <Contact {...main} />
      </div>
      <Scrollbar />
    </>
  );
}

export default App;
