
function About({ bio, ido, years, image,img2,video, resumedownload,companyLogo }) {
    return (
        <>
            <section id="about" className="section pp-scrollable d-flex align-items-center position-absolute ">
                <div className="intro">
                    <div className="scroll-wrap">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="about_card noise col-md-6 pr-md-5">
                                  <div className=" inset-0 ">
                                  <div  className="about_me ">
                                   <div className="mb-4 sub_titles">Hey,</div>
                                    <h2 className="bio" dangerouslySetInnerHTML={{ __html: bio }}></h2>
                                
                                    <div className="ido mt-5 pt-2 mb-5">
                                    <div className="company">
                                       <img src={companyLogo} alt="" className="c_img " />
                                        <h4>As a Support Instructor I perform planning, executing 
                                            content and
                                                        contribute to the company's community.</h4>
                                    </div>
                                    </div>
                                    <a target="_blank" rel="noreferrer" href={resumedownload} className="btn">Download Resume</a>

                                  </div>
                                  

                                </div>
                                   </div>
                                

<div className="about_card noise card_a mt-5 mt-md-0 col-md-6 col-lg-5 offset-lg-1">
                                   <div className="about_me  position-relative ">
                                   <img alt="" className="border-radius w-100" src={image} loading="lazy" />
                                   
                                    <div className="ido mt-5  mb-2">
                                    <div className="achivement">
                                        <h4> <span className="number">3</span> YEARS
EXPERIENCE
WORKING</h4>
                                    </div>
                                    </div>
                                    <div className="foter"></div>

                                </div>
                                   </div>



                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About