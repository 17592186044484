
function Work({ work }) {
    return (
        <>
            <section id="testimonials"
                className="  navbar-is-white text-white section pp-scrollable d-flex align-items-center position-absolute"
                style={{ backgroundImage: "url('')" }}>
                <div className="intro">
                    <div className=" styles_features__7NXFV scroll-wrap">
                        <div className="container">
                            <div className="row">
                            {/* <div className="col-sm-3 col-12 mb-5 "> */}
                            <div className="col-sm-10  col-lg-3 mb-5  ">
                                                                <div data-global-hover="true"
                                                                        className="por flex column h-100 border-radius-4 bg-gray-darkest p-12 styles_feature__C2vAD">
                                                                        <h4
                                                                                className="text-white f-size-title-l mb-2 nowrap satoshi">
                                                                                Simple Edit</h4>
                                                                        <p className="f-size-display-l text-gray">Crop,
                                                                                trim, adjust or resize with one click.
                                                                        </p>
                                                                        <div className="simpleedit flex center mt-auto">
                                                                                <div className="image">
                                                                                        <img className="edit_img" src="img/work/edit.webpg" alt="" />
                                                                                </div> 
                                                                        </div>
                                                                               <a className="hire_button btn" href="https://www.linkedin.com/in/arifalmas/">Hire Me</a>
                                                                </div>
                                                        </div>
                                                        <div className="col-sm-10 col-md-5 col-12 mb-5">
                                                        {/* <div className="col-sm-5 col-12 mb-5"> */}
                                                                <div data-global-hover="true"
                                                                        className="por flex column h-100 border-radius-4 bg-gray-darkest p-12 styles_feature__C2vAD">
                                                                        <h4
                                                                                className="text-white f-size-title-l mb-2 nowrap satoshi">
                                                                                Make your own Timeline</h4>
                                                                        <p className="f-size-display-l text-gray">
                                                                        I will make your video more unique</p>
                                                                        <div className="timeline">
                                                                                <div className="image">
                                                                                    <img className="edit_img" src="img/work/time.gif" alt="" />
                                                                                             
                                                                                       
                                                                                </div>
                                                                            
                                                                        </div>
                                                                </div>
                                                        </div>
                                                        <div className="col-sm-10 col-12 col-md-8 col-lg-4 mb-5 ">
                                                        {/* <div className="col-sm-4 col-12 mb-5"> */}
                                                                <div data-global-hover="true"
                                                                        className="por flex column border-radius-4 bg-gray-darkest p-12 styles_feature__C2vAD styles_x2__FvchT">
                                                                    
                                                                        <h4
                                                                                className="text-white f-size-title-l mb-2 nowrap satoshi">
                                                                                Manual Subtitle</h4>
                                                                        <p className="f-size-display-l text-gray">You
                                                                                need to create manual hot subtitles for your video</p>
                                                                        <div
                                                                                className="border-radius-4 mt-auto overflow-hidden image video">
                                                                               <video src="video//subtitle.mp4" autoplay=""
                                                                                        loop="true" muted="true"></video>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                        <div className="figma col-sm-10 col-md-5 col-12 mb-5">
                                                                <div data-global-hover="true"
                                                                        className="por_figma flex column h-100 border-radius-4 bg-gray-darkest p-12 styles_feature__C2vAD">
                                                                        <h4
                                                                                className="text-white f-size-title-l mb-2 nowrap satoshi">
                                                                               Make your Design unique </h4>
                                                                        <p className="f-size-display-l text-gray">
                                                                                Let's visualized your content with unique design.
                                                                                </p>
                                                                              
                                                                     
                                                                </div>
                                                        </div>
                                                        {/* <div className="col-sm-3 col-12 mb-5"> */}
                                                        <div className="col-sm-10 col-md-8 col-lg-3 col-12 mb-5">
                                                                <div data-global-hover="true"
                                                                        className="por flex column h-100 border-radius-4 bg-gray-darkest p-12 styles_feature__C2vAD">
                                                                       
                                                                        <h4
                                                                                className="text-white f-size-title-l mb-2 nowrap satoshi">
                                                                               Softwares</h4>
                                                                        <p className="f-size-display-l text-gray">
                                                                                Are waiting for your projects</p>
                                                                                <img className="soft " src="img/work/softwares.webp" alt="" />
                                                                        <div className="mt-auto image">
                                                                        </div>
                                                                </div>
                                                        </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
        </>
    )
}

export default Work