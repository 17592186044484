import React from 'react'

function Partners({ partners }) {
    return (
        <>
            <section id="partners" className="section pp-scrollable position-absolute">
            <div className="intro">
                    <div className="scroll-wrap">
                        <div className="container">
                            <div className="d-block d-md-flex align-items-center justify-content-between">
                                <h2 className="mb-0 mb-3 mb-md-0 text-white">Latest Blogs</h2>
                                <a href="https://arifalmas.medium.com/" className="btn">View all</a>
                            </div>
                            <div className="mt-5 pt-4 ">
                                <div className="news-row row">
                                    <div className="blogs blog_one col-lg-3">
                                        <a href="#"><img alt="" className="w-100" src="img/blogs/reactv19.webp" /></a>
                                        <p className="mt-3">Blog 01 / Apr 8, 2024</p>
                                        <h4 className="mt-2 text-white">New Feature in React v19</h4>
                                        <a href="https://arifalmas.medium.com/new-features-in-react-19-d5a414114f5d">Read more 🡒</a>
                                    </div>
                                    <div className="blogs blog_one col-lg-3">
                                        <a href="#"><img alt="" className="w-100" src="img/blogs/paper.webp" /></a>
                                        <p className="mt-4">News 01 / June 21, 2023</p>
                                        <h4 className="mt-3 text-white">Daily Ittefaq featured Arif Almas</h4>
                                        <a href="https://www.ittefaq.com.bd/649138/%E0%A6%86%E0%A6%B0%E0%A6%BF%E0%A6%AB%E0%A7%87%E0%A6%B0-%E0%A6%B8%E0%A7%8D%E0%A6%AC%E0%A6%AA%E0%A7%8D%E0%A6%A8%E0%A6%9C%E0%A7%9F">Read more 🡒</a>
                                    </div>
                                    <div className="blogs blog_one col-lg-3">
                                        <a href="#"><img alt="" className="w-100" src="img/blogs/redux.webp" /></a>
                                        <p className="mt-4">Blog 02 / June, 2020</p>
                                        <h4 className="mt-3 text-white">Why should you learn redux</h4>
                                        <a href="#">Read more 🡒</a>
                                    </div>
                                    <div className="blogs blog_one col-lg-3">
                                        <a href="#"><img alt="" className="w-100" src="img/blogs/resume.webp" /></a>
                                        <p className="mt-4">Blog 03 / Sep, 2023</p>
                                        <h4 className="mt-3 text-white">How to write a professional resume</h4>
                                        <a href="#">Read more 🡒</a>
                                    </div>
                                    <div className="blogs blog_one col-lg-3">
                                        <a href="#"><img alt="" className="w-100" src="img/blogs/code.webp" /></a>
                                        <p className="mt-4">Blog 04 / June, 2020</p>
                                        <h4 className="mt-3 text-white">The 4 Pillars of Clean Code</h4>
                                        <a href="#">Read more 🡒</a>
                                    </div>
                                   <div className="blogs blog_one col-lg-3">
                                        <a href="https://www.linkedin.com/posts/arifalmas_i-always-follow-my-own-strategy-when-learning-activity-7206708683840405504-xh7m?utm_source=share&utm_medium=member_desktop"><img alt="" className="w-100" src="img/blogs/fb-post.webp" /></a>
                                        <p className="mt-4">Blog 05 / Jun, 2024</p>
                                        <h4 className="mt-3 text-white">Strategy for learning new.</h4>
                                        <a href="https://www.linkedin.com/posts/arifalmas_i-always-follow-my-own-strategy-when-learning-activity-7206708683840405504-xh7m?utm_source=share&utm_medium=member_desktop">Read more 🡒</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Partners
