

function Skills({ skills, title, subtitle,typeWriter }) {
    return (
        <>
            <section id="skills" className="section pp-scrollable d-flex align-items-center position-absolute">
                <div className="intro">
                    <div className="scroll-wrap">
                        <div className="container">
                            <div className="row skill_row align-items-center">
                             <div className=" col-md-6 pr-md-5">
                                                                <div data-global-hover="true"
                                                                        className="por_skill flex column border-radius-4 overflow-hidden bg-gray-darkest p-12 styles_feature__JumHp styles_stock__MBToi styles_x2__KFGap">
                                                                        <div className="poa w-full top-0 left-0 right-0">
                                                                            <img className="code_img" src="img/code.gif" alt="" />
                                                                               
                                                                        </div>
                                                                        <div className="mt-auto">
                                                                              
                                                                                <p className="skill_subtitle">
                                                                                I have experience in building websites and applications using the latest technologies.
                                                                                </p>
                                                                               
                                                                        </div>
                                                                </div>
                                                                <div data-global-hover="true"
                                                                        className="por_skill2 flex column border-radius-4 overflow-hidden bg-gray-darkest p-12 styles_feature__JumHp styles_stock__MBToi styles_x2__KFGap">
                                                                        <div className="poa_img w-full top-0 left-0 right-0">
                                                                            <a href="https://learnwithsumit.com/certificates/verify/LWSCTXN-F012LDGO"><img className="certificate" src="img/skills/LWS-Excellence-Certificate.jpg" alt="" /></a>
                                                                            <a href="https://learnwithsumit.com/certificates/verify/LWSCTXN-F012LDGO"><img className="certificate2" src="img/skills/LWS-Recommendation-Letter.jpg" alt="" /></a>
                                                                               
                                                                        </div>
                                                                    
                                                                       
                                                                </div>
                                                        </div>
                                                    <div className="skill_div por_dev col-md-6 pr-md-5"> 
{skills?.map(({ title, skills }) => (
    <div className="exp_skills ">
        <h4 className="text-white">{title}</h4>
        <div className="mb-4 pt-2 skills_wrapper ">
            {skills?.map((skill) => (
                <div className="skill-item">
                    <h6 className="mt-0 text-gray">{skill}</h6>
                </div>
            ))}
        </div>
    </div>
))} 
</div>
                                                        
                                                                        
                                                                        
                                                             
                                                               
                                                      
                                                        </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Skills
