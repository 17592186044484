export const main = {
    "name": "Arif Almas",
    "occupation": "A Front-End Web developer",
    "description": "Developer | Designer | Editor",
    "image": "profilepic.webp",
    "bio": "I'm Arif Almas. I am a web developer with a passion for building beautiful, responsive websites and applications excellent reputation for resolving problems and improving customer satisfaction. I'm a Front End Web developer to help you to build your personal website with seamless customization. I've worked on many complex data management project and I've been learning each day. I can work and manage from development to test to deployment & my another expertise is, I write very clean and readable code.",
    "email": "arifalmasdev@gmail.com",
    "phone": "+880 16480 43030",
    "address": "Adamdighi,Bogura,Bangladesh",
    "website": "https://arifalmas.com",
    "resumedownload": "https://drive.google.com/file/d/1NQfgZ02mvE_VVaQ3N6abBSKoiWO2hcUU/view?usp=sharing",
    "social": [
        {
            "name": "linkedin",
            "url": "https://www.linkedin.com/in/arifalmas",
            "iconName": "logo-linkedin"
        },
        {
            "name": "youtube",
            "url": "https://www.youtube.com/@arifalmasar",
            "iconName": "logo-youtube"
        },
        {
            "name": "twitter",
            "url": "https://twitter.com/arifalmass",
            "iconName": "logo-twitter"
        },
        {
            "name": "facebook",
            "url": "https://www.facebook.com/coder.arifalmas",
            "iconName": "logo-facebook"
        },
        {
            "name": "instagram",
            "url": "https://www.instagram.com/arifalmasdev/",
            "iconName": "logo-instagram"
        },
        {
            "name": "github",
            "url": "https://github.com/arifalmas",
            "iconName": "logo-github"
        }
    ]
}

export const about = {
  
    "bio": "I am a web developer with a passion for building beautiful, responsive websites and applications excellent reputation for resolving problems and improving customer satisfaction. I'm a Front End Web developer to help you to build your personal website with seamless customization. I've worked on many complex data management project and I've been learning each day. I can work and manage from development to test to deployment & my another expertise is, I write very clean and readable code.",
    "years": 3,
    "image": "img/profilepic.webp",
    "img2": "img/prohfilepic.webp",
    "companyLogo":"img/lws-logo=White.webp",
    "resumedownload": "https://drive.google.com/file/d/1NQfgZ02mvE_VVaQ3N6abBSKoiWO2hcUU/view?usp=sharing",
}



export const skills = {
   
    skills: [
        
        {
            "title": "Front-end",
            skills: ["JavaScript", "React JS", "Next JS", "HTML/CSS", "UI/UX Design", "Jquery", "Redux"]
        },
        {
            "title": "Back-end",
            skills: ["Node JS", "Express JS", "Rest API",]
        },
        {
            "title": "Database",
            skills: ["Firebase", "redis"]
        },
        
        {
            "title": "Cloud",
            skills: ["Google", "AWS", "Digital ocean", "Heroku", "Vercel"]
        },
        {
            "title": "Libraries",
            skills: ["Tailwind CSS", "Bootstrap", "Material UI"]
        },
        {
            "title": "Others",
            skills: ["Git", "GitHub", "PWA", "Figma","Google Map",]
        },
    ]
}

export const projects = [
    {
        "title": "Farmat Grocery Website",
        "category": "Website",
        "image": "farmat.webp",
        "url": "https://farmart-grocery-ecommerce-nextjs.vercel.app/"
    },
    {
        "title": "Zomato Clone",
        "category": "Website",
        "image": "Zomato.webp",
        "url": "https://zomato-clone-arif.netlify.app/k"
    },
    {
        "title": "Unsplash Clone",
        "category": "Website",
        "image": "unsplash.webp",
        "url": "https://unsplash-clone-arif.netlify.app/"
    },
    {
        "title": "Zeppto website",
        "category": "Website",
        "image": "zeptto.webp",
        "url": "https://zeppto.netlify.app"
    },
    {
        "title": "Business Website",
        "category": "Website",
        "image": "megakit.webp",
        "url": "https://megakit-web-arif.netlify.app/"
    },
    {
        "title": "Weather Application",
        "category": "Website",
        "image": "weather.webp",
        "url": "https://weather-app-arif.netlify.app/"
    },
    {
        "title": "Developer Portfolio",
        "category": "Website",
        "image": "portfolio.webp",
        "url": "https://arifalmas.netlify.app/"
    },
    {
        "title": "LWS Quiz Application",
        "category": "Website",
        "image": "lws-quiz.webp",
        "url": "https://lws-quiz-app-arif.netlify.app/"
    },
    
]

export const partners = [
    {
        image: "ezzy-travels.webp"
    },
    {
        image: "2.webp"
    },
    {
        image: "4.png"
    },
]

export const work = [
    
]

